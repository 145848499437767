<template>
    <v-row no-gutters>
        <v-col class="main-col" lg="7">
            <v-container class="pa-4 pa-sm-12 pt-sm-8">
                <v-card elevation="0" rounded class="pa-4 pa-sm-6">
                    <img class="mb-4 mb-sm-10" style="width: 150px;" width="232px;" :src="require(`@/assets/svg/voiceform_full_black.svg`)" />
                     <v-fade-transition>
                        <div v-if="show">
                            <div
                                v-if="!hideBackButton" 
                            >
                                <v-btn
                                    class="back-btn mt-4 ml-n3"
                                    :elevation="0"
                                    @click="$router.go(-1)"
                                    color="grey"
                                    text
                                >
                                    <v-icon
                                        left 
                                    >
                                        mdi-arrow-left
                                    </v-icon>
                                    Back
                                </v-btn>
                            </div>
                            <div
                                class="back-button-spacer"
                                v-else 
                            >
                            </div>
                            <div class="text-h5 text-md-h5 mt-4 mt-sm-10 mb-8">
                                <slot name="heading-text"></slot>
                            </div>
                   
                            <slot>
                                <!-- content -->
                            </slot>
                        </div>
                    </v-fade-transition>
                </v-card>
            </v-container>
        </v-col>
        <v-col lg="5" class="d-none d-lg-block sub-col pa-10">
            <div class="ma-auto">
                <img class="mt-10 illustration" style="width: 100%" width="487px" :src="require(`@/assets/svg/illustration.svg`)"/>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "UserInfoLayout",
    data() {
        return {
            show: false
        };
    },
    computed: {
        ...mapGetters({
            useType: 'userInfoForm/useType',
        }),
 
    },
    props: {
        hideBackButton: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        setTimeout(this.show=true, 100)
    },
    created() {
        const currentPath = this.$route.name;
        if(currentPath !== "user-info" && !this.useType) {
            return this.$router.replace({ name: 'user-info' });
        }
    }
};
</script>

<style lang="scss">
    :root {
        --overflow: hidden;
    }
</style>

<style scoped lang="scss">
    .sub-col {
        background-color: var(--v-primary-lighten5);
        overflow: hidden;
    }
    .main-col{
        position: relative;
        box-shadow: -20px 0px 30px 75px rgba(0, 0, 0, 0.05);
        height: 100vh;
        overflow: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .main-col::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .illustration{
        margin-left: -100px;
    }
    .back-button-spacer {
        padding-top: 52px;

        @media screen and (max-width: 600px) {
            padding-top: 0;
        }
    }
</style>
